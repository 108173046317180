import React from "react"

import Layout from "@src/components/Layout"
import CommunityCallout from "@src/components/CommunityCallout"
import getAppUrl from "@src/utils/getAppUrl"

import Integrations from "@src/icons/screenshots/integrations.png"
import CommunityPluginsIcon from "@src/icons/v2/community-plugins.svg"
import GitIntegrationsIcon from "@src/icons/v2/git-integrations.svg"
import GoogleSheetsIcon from "@src/icons/v2/google-sheets-icon.svg"
import AsanaIcon from "@src/icons/v2/asana-icon.svg"
import TwitterIntergrations from "@src/icons/v2/twitter-integrations.svg"
import YoutubeIntegration from "@src/icons/v2/youtube-integrations.svg"
import StripeIntegration from "@src/icons/v2/stripe-integrations.svg"
import SlackIntegration from "@src/icons/v2/slack-integrations.svg"
import HubspotIntegration from "@src/icons/v2/hubspot-integrations.svg"
import PinterestIntegration from "@src/icons/v2/pinterest-integrations.svg"
import GithubIntegrations from "@src/icons/v2/github-integrations.svg"
import TwilioIntegrations from "@src/icons/v2/twilio-integrations.svg"
import MailgunIntegrations from "@src/icons/v2/mailgun-integrations.svg"
import NetlifyIntegrations from "@src/icons/v2/netlify-integrations.svg"
import TravisIntegrations from "@src/icons/v2/travis-integrations.svg"
import CircleIntegrations from "@src/icons/v2/circle-integrations.svg"
import GitlabIntegrations from "@src/icons/v2/gitlab-integrations.svg"
import BitbucketIntegrations from "@src/icons/v2/bitbucket-integrations.svg"
import LambdaIntegrations from "@src/icons/v2/lambda-integrations.svg"
import GoogleCloudIntegrations from "@src/icons/v2/google-cloud-integrations.svg"
import GoogleIntegrations from "@src/icons/v2/google.svg"
import FacebookIntegrations from "@src/icons/v2/facebook.svg"
import MongoIntegrations from "@src/icons/v2/mongodb.svg"
import MysqlIntegrations from "@src/icons/v2/mysql.svg"
import PostgresqlIntegrations from "@src/icons/v2/postgresql.svg"
import OG from "@src/icons/og/OG_Integrations.jpeg"

import * as styles from "./styles.module.scss"
import Splash from "./components/Splash"
import Row from "../components/Row"
import Horizontal from "./components/Horizontal"
import ImageColumn from "./components/ImageColumn"
import Features from "./components/Features"

const IndexPage = ({ location }) => {
  return (
    <Layout
      title="We have integrations ready and coded for you"
      description="A marketplace full of integrations backed by community, helps save time and money."
      url="https://canonic.dev/features/integrations"
      location={location}
      ogImage={OG}
    >
      <Splash
        title="We have integrations ready and coded for you"
        subtitle="A marketplace full of integrations backed by community, helps save time and money."
        screenshot={Integrations}
      />
      <Row>
        <Horizontal>
          <ImageColumn
            title="Plug & Play from community plugins!"
            subtitle="Find the best integration from a host of integrations pushed by
            Community & Canonic itself. The list is ever growing!"
            icon={CommunityPluginsIcon}
          />
          <ImageColumn
            title="Plug & Play from your own git integrations"
            subtitle="Plug your own integration directly into Canonic using any git
            service of your choice or easily create one."
            icon={GitIntegrationsIcon}
          />
        </Horizontal>
      </Row>
      <Row>
        <h2 className={styles.title}>Integrations</h2>
        <p className={styles.subtitle}>Integrations we offer</p>
        <Features
          horizontal
          features={[
            {
              icon: SlackIntegration,
              title: "Slack",
              subtitle:
                "Slack is an enterprise software platform that allows teams and businesses of all sizes to communicate effectively.",
              tag: "Message",
            },

            {
              icon: TwilioIntegrations,
              title: "Twilio",
              subtitle:
                "Twilio is a cloud communication company that enables users to use standard web languages to build voice, VoIP, and SMS apps via a web API.",
              tag: "Message",
            },

            {
              icon: MailgunIntegrations,
              title: "Mailgun",
              subtitle:
                "Mailgun offers APIs that allow users to send, receive and track emails.",
              tag: "Message",
            },
            {
              icon: NetlifyIntegrations,
              title: "Netlify",
              subtitle:
                "Netlify is a web development tool that automates code to build and deploy sites and web applications.",
              tag: "Build",
            },
            {
              icon: TravisIntegrations,
              title: "Travis CI",
              subtitle: "Hosted Continuous Integration Platform",
              tag: "Build",
            },
            {
              icon: CircleIntegrations,
              title: "Circle CI",
              subtitle:
                "CircleCI is a continuous integration and delivery platform that automates development workflows and IT operations.",
              tag: "Build",
            },
            {
              icon: GitlabIntegrations,
              title: "Gitlab",
              subtitle:
                "GitLab is the DevOps platform that empowers organizations to maximize the overall return on software development.",
              tag: "Build",
            },
            {
              icon: BitbucketIntegrations,
              title: "Bitbucket",
              subtitle:
                "Bitbucket is a Git and Mercurial code management and collaboration platform used by professional teams to build, test and deploy software.",
              tag: "Build",
            },
            {
              icon: LambdaIntegrations,
              title: "AWS Lambda",
              subtitle:
                "AWS Lambda is a serverless, event-driven compute service that lets you run code for virtually any type of application or backend services.",
              tag: "Function",
            },
            {
              icon: GoogleCloudIntegrations,
              title: "Google Cloud",
              subtitle:
                "Google Cloud Function is a scalable pay-as-you-go functions as a service (FaaS) to run your code with zero server management.",
              tag: "Function",
            },
            {
              icon: GoogleIntegrations,
              title: "Google",
              subtitle:
                "OAuth's implementation of Google that lets you sign in using Google.",
              tag: "Social Sign In",
            },
            {
              icon: FacebookIntegrations,
              title: "Facebook",
              subtitle:
                "OAuth's implementation of Facebook that lets you sign in using Facebook.",
              tag: "Social Sign In",
            },
            {
              icon: GithubIntegrations,
              title: "Github",
              subtitle:
                "OAuth's implementation of GitHub that lets you sign in using GitHub.",
              tag: "Social Sign In",
            },
            {
              icon: MongoIntegrations,
              title: "MongoDB",
              subtitle:
                "MongoDB is a next-generation database that helps businesses transform their industries by harnessing the power of data.",
              tag: "Database",
            },
            {
              icon: MysqlIntegrations,
              title: "MySQL",
              subtitle:
                "MySQL is an open-source database that supports scalable web-based and embedded database applications.",
              tag: "Database",
            },
            {
              icon: PostgresqlIntegrations,
              title: "PostgreSQL",
              subtitle:
                "PostgreSQL is an object-relational database management system with an emphasis on extensibility and standards-compliance.",
              tag: "Database",
            },

            {
              icon: GoogleSheetsIcon,
              title: "Google Sheets",
              subtitle: `Create and collaborate on online spreadsheets in real-time and from any device.`,
              tag: "Analytics",
            },

            {
              icon: YoutubeIntegration,
              title: "Youtube",
              subtitle: `YouTube is a video-sharing platform, allows users to upload, view, and share videos, including movie and music clips and amateur content.`,
              tag: "Analytics",
            },

            {
              icon: SlackIntegration,
              title: "Slack",
              subtitle: `Slack is an enterprise software platform that allows teams and businesses of all sizes to communicate effectively.`,
              tag: "Collaboration",
            },
            {
              icon: GithubIntegrations,
              title: "Github",
              subtitle: `GitHub provides code hosting services that allow developers to build software for open-source and private projects in organizations.`,
              tag: "Collaboration",
            },
            {
              icon: AsanaIcon,
              title: "Asana",
              subtitle: `Asana is a computer software company that specializes in the fields of work management and productivity.`,
              tag: "Management",
            },
            {
              icon: TwitterIntergrations,
              title: "Twitter",
              subtitle: `Twitter is a social networking platform that allows its users to send and read micro-blogs of up to 280-characters known as tweets.`,
              tag: "Management",
            },
            {
              icon: StripeIntegration,
              title: "Stripe",
              subtitle: `Stripe is a developer-oriented commerce company helping small and large companies accept web and mobile payments.`,
              tag: "Management",
            },
            {
              icon: HubspotIntegration,
              title: "Hubspot",
              subtitle:
                "HubSpot develops cloud-based, inbound marketing software that allows businesses to transform the way that they market online.",
              tag: "Management",
            },
            {
              icon: PinterestIntegration,
              title: "Pinterest",
              subtitle:
                "Pinterest is a visual bookmarking tool for saving and discovering creative ideas.",
              tag: "Management",
            },
          ]}
        />
      </Row>
      <CommunityCallout />
    </Layout>
  )
}

export default IndexPage
